import React, { useEffect, useState } from 'react';
import {
  MDBBtn,
  MDBCol,
  MDBContainer,
  MDBIcon,
  MDBInput,
  MDBModal,
  MDBModalBody, MDBRow,
  MDBTable,
  MDBTableBody,
} from 'mdbreact';
import connect from 'react-redux/es/connect/connect';
import InputRange from '../dashboard/inputRange';
import UserList from '../dashboard/userList';
import { toggleModal } from '../../storage/actions/modalsActions';
import {
  fetchNearByContractors,
  setCurrentApplicants,
  sendInviteFromWorkOrderToContractor, sendInvitesFromWorkOrderToContractors,
} from '../../storage/actions/workOrdersActions';
import Loader from '../dashboard/common/Loader';
import { reloadData } from '../../storage/actions/applicationStateActions';
import getContractorGroups from './../../utils/api/get/getContractorGroups';
import Select from 'react-select';
import { white as customSelectStyle } from './../../utils/customSelectStyle';

const NearByContractorModal = ({
  modalState,
  toggleModal,
  currentWorkOrder,
  availableApplicants,
  fetchNearByContractors,
  availableApplicantsLoaded,
  sendInviteFromWorkOrderToContractor,
  sendInvitesFromWorkOrderToContractors,
  routedApplicants,
  reloadData,
}) => {
  const [range, changeRange] = useState(50);
  const [isFilteredByWorkedBefore, setIsFilteredByWorkedBefore] = useState(false);
  const [isPreferred, setIsPreferred] = useState(true);
  const [invitedContractors, setInvitedContractors] = useState([]);
  const [preferredGroups, setPreferredGroups] = useState([]);
  const [selectedGroups, setSelectedGroups] = useState([]);
  const [selectedContractors, setSelectedContractors] = useState(new Set());
  const [contractorList, setContractorList] = useState(availableApplicants || []);
  const [fullSelectCount, setFullSelectCount] = useState(0);

  const resetSelect = () => setSelectedContractors(new Set());

  const getAvailableToInviteContractors = allContractors => allContractors.filter(user => {
    const isBlocked = Object.values(user.block).some(el => el);
    const isInvited = invitedContractors.includes(user.userId);
    return !(isInvited || isBlocked);
  });

  const updateContractorList = () => {
    if (availableApplicants) {
      const filteredUsers = availableApplicants.filter(user => {
        if (isFilteredByWorkedBefore && !selectedGroups.length) {
          return user.completedWorkOrderCount > 0
        }
        if (isFilteredByWorkedBefore && selectedGroups.length) {
          return user.completedWorkOrderCount > 0 && user.groups.some(userGroup => selectedGroups.find(selectedGroup => selectedGroup.value === userGroup.groupId))
        }
        if (!isFilteredByWorkedBefore && selectedGroups.length) {
          return user.groups.some(userGroup => selectedGroups.find(selectedGroup => selectedGroup.value === userGroup.groupId))
        }
        return true
      })

      setContractorList(filteredUsers);
      const availableToInvite = getAvailableToInviteContractors(filteredUsers);
      setFullSelectCount(availableToInvite.length);
      resetSelect();
    }
  };

  useEffect(() => {
    getContractorGroups().then(groups => {
      setPreferredGroups([
        { label: "All groups", value: 'all' },
        ...groups.map(item => ({ label: item.name, value: item._id }))])
    })
  }, [])

  useEffect(() => {
    if (currentWorkOrder) fetchNearByContractors(currentWorkOrder._id, range);
    resetSelect();
  }, [range, currentWorkOrder]);

  useEffect(() => {
    setInvitedContractors(routedApplicants?.data?.map(item => item.contractor_id) || []);
  }, [routedApplicants]);

  useEffect(() => {
    updateContractorList();
  }, [availableApplicants, isFilteredByWorkedBefore, selectedGroups.length]);

  const inviteContractor = (user) => {
    sendInviteFromWorkOrderToContractor(
      {
        workOrdersId: [currentWorkOrder._id],
        contractorId: user.userId,
      },
      true,
    )
      .then(() => {
        setInvitedContractors([
          ...invitedContractors,
          user.userId,
        ]);
      });
  };

  const closeModal = () => {
    reloadData();
    toggleModal(!modalState.isOpen);
  };

  const handlerWorkedBeforeFilter = () => {
    setIsFilteredByWorkedBefore(!isFilteredByWorkedBefore);
  };

  const handlerPreferred = (newValue, actionMeta) => {
    switch (actionMeta.action) {
      case 'remove-value': {
        newValue = selectedGroups.filter((v) => {
          return v.value !== actionMeta.removedValue.value
        });
      }
        break;
      case 'select-option': {
        const option = actionMeta.option
        if (option.value === 'all') {
          newValue = preferredGroups.filter(v => v.value !== 'all')
        }
        break;
      }
      case 'clear':
        newValue = [];
        break;
    }

    setSelectedGroups(newValue);
  };

  const handlerSelectAll = (event) => {
    const newSelect = event.target.checked
      ? getAvailableToInviteContractors(contractorList).map(item => item.userId)
      : [];
    setSelectedContractors(new Set(newSelect));
  };

  const bulkInviteContractors = () => {
    sendInvitesFromWorkOrderToContractors(
      currentWorkOrder._id,
      [...selectedContractors],
      true,
    )
      .then(success => {
        if (success) {
          setInvitedContractors([
            ...invitedContractors,
            ...selectedContractors,
          ]);
          resetSelect();
        }
      });
  };

  const handlerSingleSelect = (event, userId) => {
    const newSelectList = new Set([...selectedContractors]);
    if (event.target.checked) {
      newSelectList.add(userId);
    } else {
      newSelectList.delete(userId);
    }
    setSelectedContractors(newSelectList);
  };

  return (
    modalState.isOpen && availableApplicants
      ? (
        <MDBModal
          className="applicantappiliedwo"
          isOpen={modalState.isOpen}
          toggle={closeModal}
          fullHeight
          scrollable
          position="right"
        >
          <MDBModalBody
            style={{
              overflow: 'hidden auto',
              color: 'white',
            }}
          >
            <MDBContainer fluid>
              <div className="d-flex uppertwodivcdc">
                <MDBCol md="12" className="tencoldivcont">
                  <h6 style={{ fontWeight: 'bold' }} className="font14">
                    {`Near By Contractor(${contractorList.length})`}
                  </h6>
                </MDBCol>
                <MDBIcon
                  icon="arrow-circle-left"
                  size="lg"
                  style={{
                    color: '#41566a',
                    cursor: 'pointer',
                  }}
                  onClick={closeModal}
                />
              </div>
            </MDBContainer>
            {
              availableApplicantsLoaded
                ? (
                  <>
                    <MDBContainer
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                      }}
                    >
                      {!currentWorkOrder.isRemote &&
                        <>
                          <MDBCol md="6">
                            <InputRange defaultValue={range} handler={changeRange} />
                          </MDBCol>
                          <MDBCol md="4" style={{ paddingRight: '26px' }}>
                            <div
                              className="selectall_checkbox d-flex flex-align-center"
                              style={{ justifyContent: 'flex-end' }}
                            >
                              <span className="font13" style={{ padding: '0px' }}>worked with before</span>
                              <MDBInput
                                className="float-right"
                                label="."
                                type="checkbox"
                                id="isFilteredByWorkedBefore"
                                checked={isFilteredByWorkedBefore}
                                onChange={handlerWorkedBeforeFilter}
                              />
                            </div>
                            <div
                              className="selectall_checkbox d-flex flex-align-center"
                              style={{ justifyContent: 'flex-end' }}
                            >
                              <Select
                                isClearable
                                isSearchable
                                isMulti
                                placeholder="Preferred groups"
                                options={preferredGroups}
                                value={selectedGroups}
                                styles={customSelectStyle}
                                onChange={handlerPreferred}
                              />
                            </div>
                          </MDBCol>
                        </>
                      }
                    </MDBContainer>
                    <MDBContainer>
                      <MDBRow className="justify-content-between">
                        <MDBCol className="flex-grow-0 flex-box align-items-center">
                          <MDBInput
                            type="checkbox"
                            id="All"
                            name="All"
                            checked={
                              contractorList.length
                              && selectedContractors.size === fullSelectCount
                            }
                            onChange={handlerSelectAll}
                            label="Select All"
                            labelClass="text-in-one-line"
                          />
                        </MDBCol>
                        <MDBCol className="flex-grow-0">
                          {currentWorkOrder.isRemote &&
                            <div
                              className="selectall_checkbox d-flex flex-align-center"
                              style={{ justifyContent: 'flex-end', minWidth: 200 }}
                            >
                              <Select
                                isClearable
                                isSearchable
                                isMulti
                                placeholder="Preferred groups"
                                options={preferredGroups}
                                value={selectedGroups}
                                styles={customSelectStyle}
                                onChange={handlerPreferred}
                              />
                            </div>
                          }
                          <MDBBtn
                            color="info"
                            onClick={bulkInviteContractors}
                            disabled={!selectedContractors?.size}
                            className="invitebutton"
                          >
                            {`Invite selected (${selectedContractors.size})`}
                          </MDBBtn>
                        </MDBCol>
                      </MDBRow>
                    </MDBContainer>
                    <MDBContainer fluid className="d-flex">
                      <MDBCol md="12" className="clientfind10">
                        <MDBTable className="clientfind_table" borderless style={{ marginTop: '-10px' }}>
                          <MDBTableBody>
                            {
                              (contractorList && contractorList.length > 0)
                              && (
                                <tr>
                                  <td>
                                  
                                  <UserList
                                    users={contractorList}
                                    handler={inviteContractor}
                                    invitedContractors={invitedContractors}
                                    profileInfo={(user) => {
                                      toggleModal(true, 'contractorDetailsModal', false, { contractorId: user.userId });
                                    }}
                                    blocked={(user) => {
                                      toggleModal(true, 'blockModal', false, { contractorId: user.userId });
                                    }}
                                    selectedContractors={selectedContractors}
                                    handlerCheckbox={handlerSingleSelect}
                                  />
                                  </td>
                                </tr>
                              )
                            }
                          </MDBTableBody>
                        </MDBTable>
                      </MDBCol>
                      <MDBCol md="2" className="clientfind2">
                        <div className="optiondivclient  blankdiv" />
                      </MDBCol>
                    </MDBContainer>
                  </>
                )
                : <Loader />
            }
          </MDBModalBody>
        </MDBModal>
      )
      : null
  );
};

const mapDispatchToProps = {
  toggleModal,
  setCurrentApplicants,
  fetchNearByContractors,
  sendInviteFromWorkOrderToContractor,
  sendInvitesFromWorkOrderToContractors,
  reloadData,
};

const mapStateToProps = state => ({
  currentWorkOrder: state.workOrder.currentWorkOrder,
  availableApplicantsLoaded: state.workOrder.availableApplicantsLoaded,
  availableApplicants: state.workOrder.availableApplicants,
  modalState: state.modalState.nearByContractorModal,
  routedApplicants: state.workOrder.routedApplicants,
});

export default connect(mapStateToProps, mapDispatchToProps)(NearByContractorModal);
