import React from 'react';
import {
  MDBBtn,
  MDBCol,
  MDBContainer,
  MDBIcon,
  MDBInput,
  MDBModal,
  MDBModalBody,
  MDBRow,
} from 'mdbreact';
import connect from 'react-redux/es/connect/connect';
import DatePicker from 'react-datepicker';
import CustomTimePicker from '../layout/CustomTimePicker';
import ContactInfoForWorkOrder from '../layout/workOrderEditElements/contactInfoForWorkOrder';
import UploadDocumentsToWorkOrder from '../layout/workOrderEditElements/UploadDocumentsToWorkOrder';
import SelectWorkOrderWorkTypes from '../layout/workOrderEditElements/selectWorkOrderWorkTypes';
import SelectWorkOrderCompany from '../layout/workOrderEditElements/SelectWorkOrderCompany';
import SelectWorkProject from '../layout/workOrderEditElements/SelectWorkOrderProject';
import EditorInput from '../layout/workOrderEditElements/EditorInput';
import Loader from '../dashboard/common/Loader';
import Notification from '../notification';
import { toggleModal } from '../../storage/actions/modalsActions';
import {
  bulkEditWorkOrders,
  getWorkOrdersForBulk,
} from '../../storage/actions/workOrdersActions';
import { getAccountBalanceByClient } from '../../storage/actions/profile';
import combineDateAndTime from '../../utils/addTimeToDatefromAM_PMFormatString';
import { getLocalISODate } from '../../utils/getDateWithOffset';
import { localCurrencySettings } from '../../utils/constans';
import prepareFilters from '../../utils/prepareFilters';
import { getStatus } from '../../utils/api/get/getWorkOrders';
import { endDateFilter, startDateFilter } from '../../utils/pickerDateFilters';

class BulkEditWorkOrders extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ordersInfo: [],
      isLoading: true,
      fieldsToUpdate: new Set(),
      startDate: '',
      endDate: '',
      startTime: '',
      endTime: '',
      startAMPM: '',
      endAMPM: '',
      startTimeHour: '',
      endTimeHour: '',
      startTimeMinutes: '',
      endTimeMinutes: '',
      amount: 0,
      quantity: 0,
      basicType: 'Hour',
      percentCommunication: '',
      percentWork: '',
      percentDeliverables: '',
      variableAmount: 0,
      variablePayAfter: 0,
      maxQuantity: 0,
      // eslint-disable-next-line react/no-unused-state
      variableType: 'Hour',
      contactInfo: [],
      file: [],
      selectedWorkOrderWorkTypes: [],
      totalAmount: 0,
      newTotalAmount: 0,
      fundsRemaining: 0,
      holdFounds: 0,
      potentialPay: 0,
      payOutsideOfApp: false,
      workOrderCount: 0,
      requestData: {},
    };
  }

  componentDidMount() {
    const { profile } = this.props;
    this.fetchWorkOrders();
    if (profile?.balance?.total_balance) {
      this.setState({
        fundsRemaining: this.getRemainFundsInfo(),
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const fundsRemaining = this.getRemainFundsInfo();
    if (prevState.fundsRemaining !== fundsRemaining) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ fundsRemaining });
    }
  }

  fetchWorkOrders = async () => {
    const {
      getAccountBalanceByClient,
      getWorkOrdersForBulk,
      selectedWorkOrders,
      modalState,
      search,
      filters,
      activeTab,
    } = this.props;
    await getAccountBalanceByClient();
    const requestData = modalState?.type?.mode === 'useFilters'
      ? {
        ...search,
        filters: prepareFilters(filters),
      }
      : { workOrdersId: selectedWorkOrders };
    const ordersInfo = await getWorkOrdersForBulk({ ...requestData, ...getStatus(activeTab) });
    const totalAmount = ordersInfo.reduce((acc, cur) => acc + (cur.holdFunds || 0), 0);
    const workOrderCount = ordersInfo.reduce((acc, cur) => acc + (cur.count || 0), 0);
    this.setState({
      ordersInfo,
      totalAmount,
      workOrderCount,
      requestData,
      isLoading: false,
    });
  }

  getRemainFundsInfo = () => {
    const { profile } = this.props;
    return profile?.balance?.total_balance - (profile?.balance?.on_hold || 0);
  }

  closeModal = () => {
    // eslint-disable-next-line no-shadow
    const { toggleModal, modalState } = this.props;
    toggleModal(!modalState.isOpen, 'bulkEditWorkOrders');
  }

  getUploadedDocuments = (file) => {
    this.setState({ file });
  }

  calculatePayDividedValues = () => {
    const {
      percentCommunication: communication, percentWork: work, percentDeliverables: deliverables,
    } = this.state;
    const percentCommunication = +communication || 0;
    const percentWork = +work || 0;
    const percentDeliverables = +deliverables || 0;
    if (percentCommunication && percentWork && percentDeliverables) return;
    if ((100 - (percentCommunication + percentWork + percentDeliverables)) <= 0) return;
    const dif = 100 - (percentCommunication + percentWork + percentDeliverables);
    const emptyCount = !percentCommunication + !percentWork + !percentDeliverables;
    this.setState({
      percentCommunication: (percentCommunication || dif / emptyCount).toFixed(3),
      percentWork: (percentWork || dif / emptyCount).toFixed(3),
      percentDeliverables: (percentDeliverables || dif / emptyCount).toFixed(3),
    });
  }

  handlerContactInfoChanges = newContactList => {
    this.setState({ contactInfo: newContactList });
  }

  updateTotalAmount = (isPayLocationChecked = false) => {
    const {
      amount, quantity, basicType, variableAmount: vAmount, maxQuantity: vQty,
      workOrderCount, ordersInfo
    } = this.state;
    const newCount = isPayLocationChecked ? workOrderCount : ordersInfo.filter(item => !item._id).length
    const potentialPay = amount * (basicType === 'Site' ? 1 : quantity);
    const holdFounds = vAmount
      ? potentialPay + vAmount * vQty
      : potentialPay;
    this.setState({
      potentialPay,
      holdFounds,
      newTotalAmount: holdFounds * newCount,
    });
  }

  fieldsValue = (form, fieldName) => {
    const {
      startDate, startTime, endDate, endTime,
      percentCommunication: perCommunication, percentWork: perWork,
      percentDeliverables: perDeliverables, basicType, variablePayAfter: vAfter,
      holdFounds, potentialPay, contactInfo,
      amount, variableAmount: vAmount, quantity, payOutsideOfApp,
      newTotalAmount, totalAmount, selectedWorkOrderWorkTypes, variableType,
    } = this.state;
    switch (fieldName) {
      case 'dateTimeFields': {
        return {
          startDate: combineDateAndTime(startDate, startTime),
          startTime,
          endDate: getLocalISODate(endDate, true, true),
          endTime,
        };
      }
      case 'editPay': {
        // eslint-disable-next-line max-len
        if (parseFloat(perCommunication) + parseFloat(perWork) + parseFloat(perDeliverables) !== 100) {
          return { errorMessage: 'Communication, Work and Deliverables should total to 100%.' };
        }
        if (!amount || !quantity) {
          return { errorMessage: 'Please enter correct amount and quantity in base pay section' };
        }
        return {
          amount,
          quantity,
          payBaseRateAsMinimum: form.payBaseRateAsMinimum?.checked,
          variableAmount: vAmount,
          variablePayAfter: vAfter,
          maxQuantity: quantity,
          variableType: variableType || 'Hour',
          percentCommunication: perCommunication,
          percentWork: perWork,
          percentDeliverables: perDeliverables,
          holdFounds,
          potentialPay,
          fundsNeeded: newTotalAmount - totalAmount,
        };
      }
      case 'editPayLocation': {
        return {
          payOutsideOfApp: payOutsideOfApp || false,
        }
      }
      case 'replaceContacts': {
        if (!contactInfo.length) {
          return { errorMessage: 'Please enter Correct Contact ' };
        }
        return {
          contactInfo,
        };
      }
      case 'uploadDocuments': return {
        isNeedDeliverables: form.isNeedDeliverables.checked,
      };
      case 'workType': {
        if (!(selectedWorkOrderWorkTypes && selectedWorkOrderWorkTypes.length)) {
          return { errorMessage: 'Please choose your work type' };
        }
        return {
          toolsId: selectedWorkOrderWorkTypes.map((item) => item.value),
        };
      }
      case 'deleteAllDocuments': return {
        deleteAllDocuments: form[fieldName].checked,
      };
      case 'title': {
        if (!form[fieldName].value.trim()) {
          return { errorMessage: 'Title is required' };
        }
        return {
          title: form[fieldName].value,
        };
      }
      
      case 'description': {
        if (!form[fieldName].value.trim()) {
          return { errorMessage: 'Description is required' };
        }
        return {
          description: form[fieldName].value,
        };
      } 
      
      default: {
        return { [fieldName]: form[fieldName].value || '' };
      }
    }
  }

  // eslint-disable-next-line consistent-return
  handlerSubmit = (event) => {
    const { fieldsToUpdate, file, requestData } = this.state;
    const {
      bulkEditWorkOrders,
      activeTab,
    } = this.props;
    event.preventDefault();
    const tmpFieldsToUpdate = [...fieldsToUpdate];

    if (!this.state.payOutsideOfApp && (this.state.totalAmount > this.state.fundsRemaining)) {
      return Notification('error', { message: 'Not enough funds availble' });
    }

    let data = {};
    tmpFieldsToUpdate.forEach((fieldName) => {
      data = { ...data, ...this.fieldsValue(event.target, fieldName) };
    });
    if (data.errorMessage) {
      Notification('error', { message: data.errorMessage });
    } else {
      const fd = new FormData();
      fd.append('data', JSON.stringify(data));
      if (file) {
        file.forEach((item) => {
          fd.append('file', item);
        });
      }
      fd.append('tab', activeTab);
      fd.append('workOrdersFilter', JSON.stringify({ ...requestData, ...getStatus(activeTab) }));
      bulkEditWorkOrders(fd);
    }
  }

  handlerInput = (name, value, updateTotalAmount) => {
    const { variableType, basicType } = this.state;
    const newData = {
      [name]: value || '',
    };
    if (
      name === 'quantity'
      && ((variableType === basicType) || ((variableType === 'Hour') && (basicType === 'Site')))
    ) {
      newData.variablePayAfter = value || '';
    }
    this.setState(newData, () => {
      if (updateTotalAmount) this.updateTotalAmount();
    });
  }

  handlerUpdatingFields = (name, checked) => {
    const { fieldsToUpdate } = this.state;
    const newFieldsToUpdateSet = new Set([...fieldsToUpdate]);
    if (checked) {
      newFieldsToUpdateSet.add(name);
    } else {
      newFieldsToUpdateSet.delete(name);
    }
    this.setState({
      fieldsToUpdate: newFieldsToUpdateSet,
    });
    if(name === 'editPayLocation') {
      this.updateTotalAmount(checked);
    }
  }

  render() {
    const {
      modalState,
      profile,
    } = this.props;
    const {
      fieldsToUpdate,
      startDate,
      endDate,
      startTime,
      endTime,
      startAMPM,
      endAMPM,
      startTimeHour,
      endTimeHour,
      startTimeMinutes,
      endTimeMinutes,
      amount,
      quantity,
      basicType,
      variableType,
      variableAmount: vAmount,
      variablePayAfter: vAfter,
      maxQuantity: vQty,
      percentCommunication,
      percentWork,
      percentDeliverables,
      contactInfo,
      file,
      selectedWorkOrderWorkTypes,
      totalAmount,
      newTotalAmount,
      isLoading,
      fundsRemaining,
      payOutsideOfApp,
    } = this.state;

    return (
      <MDBModal
        className="applicantappiliedwo"
        isOpen={modalState.isOpen}
        toggle={this.closeModal}
        fullHeight
        // scrollable
        position="right"
      >
        <MDBModalBody
          style={{
            overflow: 'hidden auto',
            color: 'white',
            fontSize: '0.8rem',
            padding: '1rem 0',
          }}
        >
          <MDBContainer fluid>
            <div className="d-flex uppertwodivcdc">
              <MDBCol className="tencoldivcont flex-grow-0">
                <h6 style={{ fontWeight: 'bold', whiteSpace: 'nowrap' }} className="font14">
                  Bulk Edit
                </h6>
              </MDBCol>
              <MDBCol>
                <h6
                  style={{
                    fontWeight: 'bold',
                    color: 'yellow',
                    textAlign: 'center',
                  }}
                  className="font14"
                >
                  WARNING! The checked boxes will override ALL DATA in selected work orders
                </h6>
              </MDBCol>
              <MDBIcon
                icon="arrow-circle-left"
                size="lg"
                style={{ color: '#41566a', cursor: 'pointer' }}
                onClick={this.closeModal}
              />
            </div>
          </MDBContainer>
          {
            isLoading
              ? <Loader />
              : (
                <form onSubmit={this.handlerSubmit}>
                  <MDBContainer fluid>
                    <MDBRow className="background-dark-blue h-paddings">
                      <MDBCol md="12">
                        <MDBInput
                          filled
                          type="checkbox"
                          id="dateTimeFieldsCheckbox"
                          label="Edit Date And Time Fields"
                          onChange={(event) => this.handlerUpdatingFields('dateTimeFields', event.target.checked)}
                          checked={fieldsToUpdate.has('dateTimeFields')}
                        />
                      </MDBCol>
                      {
                        fieldsToUpdate.has('dateTimeFields')
                          ? (
                            <MDBCol>
                              <MDBRow className="no-margins">
                                <MDBCol className="date-time-input-group">
                                  <label>Start Date</label>
                                  <div className="d-flex">
                                    <DatePicker
                                      className="form-control width-inherit"
                                      dateFormat="MM/dd/yyyy"
                                      /* eslint-disable-next-line camelcase */
                                      selected={startDate}
                                      onChange={(date) => this.handlerInput('startDate', date)}
                                      placeholder="Start Date"
                                      style={{
                                        marginRight: '10px',
                                      }}
                                      filterDate={startDateFilter}
                                    />
                                    <MDBIcon
                                      far
                                      icon="calendar-alt"
                                      size="lg"
                                      style={{ backgroundColor: '#1e2f41', color: '#394d61', padding: '8px' }}
                                    />
                                  </div>
                                </MDBCol>
                                <MDBCol className="date-time-input-group">
                                  <label>End Date</label>
                                  <div className="d-flex">
                                    <DatePicker
                                      className="form-control"
                                      dateFormat="MM/dd/yyyy"
                                      /* eslint-disable-next-line camelcase */
                                      selected={endDate}
                                      onChange={(date) => this.handlerInput('endDate', date)}
                                      placeholder="End Date"
                                      style={{
                                        marginRight: '10px',
                                      }}
                                      filterDate={endDateFilter}
                                    />
                                    <MDBIcon
                                      far
                                      icon="calendar-alt"
                                      size="lg"
                                      style={{ backgroundColor: '#1e2f41', color: '#394d61', padding: '8px' }}
                                    />
                                  </div>
                                </MDBCol>
                                <MDBCol className="date-time-input-group">
                                  <label>Start Time</label>
                                  <CustomTimePicker
                                    getValue={(value) => this.handlerInput('startTime', value)}
                                    dayTime={startAMPM}
                                    hours={startTimeHour}
                                    minutes={startTimeMinutes}
                                    value={startTime}
                                    placeholder="Start Time"
                                  />
                                </MDBCol>
                                <MDBCol className="date-time-input-group">
                                  <label>End Time</label>
                                  <CustomTimePicker
                                    getValue={(value) => this.handlerInput('endTime', value)}
                                    dayTime={endAMPM}
                                    hours={endTimeHour}
                                    minutes={endTimeMinutes}
                                    value={endTime}
                                    placeholder="End Time"
                                  />
                                </MDBCol>
                              </MDBRow>
                            </MDBCol>
                          )
                          : <></>
                      }
                    </MDBRow>
                    <MDBRow className="h-paddings">
                      <MDBCol>
                        <MDBRow>
                          <MDBCol>
                            <MDBInput
                              filled
                              type="checkbox"
                              id="editPayCheckbox"
                              label="Edit Pay"
                              onChange={(event) => this.handlerUpdatingFields('editPay', event.target.checked)}
                              checked={fieldsToUpdate.has('editPay')}
                            />
                          </MDBCol>
                        </MDBRow>
                        {
                          fieldsToUpdate.has('editPay')
                            ? (
                              <>
                                <MDBRow className="no-margins h-paddings">
                                  <MDBCol md="6">
                                    <MDBRow className="no-margins">
                                      <h6 style={{ fontWeight: 'bold' }}>Base Pay</h6>
                                    </MDBRow>
                                    <MDBRow className="no-margins">
                                      <MDBCol style={{ paddingLeft: '0px' }}>
                                        <div className="d-flex" style={{ marginBottom: '20px' }}>
                                          <MDBCol>
                                            <label>Amount</label>
                                          </MDBCol>
                                          <MDBCol>
                                            <input
                                              type="number"
                                              className="small-input border-none-with-padding"
                                              name="amount"
                                              value={amount}
                                              onChange={({ target }) => {
                                                const value = +target.value || 0;
                                                this.handlerInput(target.name, value, true);
                                              }}
                                            />
                                          </MDBCol>
                                        </div>
                                        <div className="d-flex" style={{ marginBottom: '20px' }}>
                                          <MDBCol>
                                            <label>{`Qty${basicType === 'Site' ? ' (Est Hours)' : ''}`}</label>
                                          </MDBCol>
                                          <MDBCol>
                                            <input
                                              type="number"
                                              className="small-input border-none-with-padding"
                                              name="quantity"
                                              value={quantity}
                                              onChange={({ target }) => {
                                                const value = +target.value || 0;
                                                this.handlerInput(target.name, value, true);
                                              }}
                                            />
                                          </MDBCol>
                                        </div>
                                        <div className="d-flex" style={{ marginBottom: '20px' }}>
                                          <MDBCol>
                                            <label>Est.Pay</label>
                                          </MDBCol>
                                          <MDBCol>
                                            {(amount * (basicType === 'Site' ? 1 : quantity)).toLocaleString('en-US', localCurrencySettings)}
                                          </MDBCol>
                                        </div>
                                      </MDBCol>
                                      <MDBCol>
                                        <div style={{ marginBottom: '30px', alignItems: 'baseline' }} className="d-flex">
                                          <MDBCol md="3">
                                            <label>Per</label>
                                          </MDBCol>
                                          <MDBCol>
                                            <div className="perdevice_hour">
                                              <select
                                                className="border-none-with-padding"
                                                name="basicType"
                                                onChange={({ target }) => {
                                                  this.handlerInput(target.name, target.value, true);
                                                }}
                                              >
                                                <option value="Hour">Hour</option>
                                                <option value="Device">Device</option>
                                                <option value="Site">Site</option>
                                              </select>
                                            </div>
                                          </MDBCol>
                                        </div>

                                        <div
                                          className="d-flex"
                                          title="This will pay the contractor the base pay rate entered as a minimum payment regardless of how long they spend onsite."
                                        >
                                          <MDBInput
                                            filled
                                            type="checkbox"
                                            label="Pay base as min."
                                            id="payBaseRateAsMinimum"
                                            name="payBaseRateAsMinimum"
                                          />
                                        </div>
                                      </MDBCol>
                                    </MDBRow>
                                  </MDBCol>
                                  <MDBCol md="5">
                                    <MDBRow className="no-margins">
                                      <h6 className="howpay" style={{ fontWeight: 'bold' }}>How Pay is Divided</h6>
                                    </MDBRow>
                                    <MDBRow className="no-margins" style={{ display: 'block' }}>
                                      <div
                                        className="flex-box flex-align-center"
                                        style={{
                                          marginBottom: '20px',
                                          justifyContent: 'space-between',
                                        }}
                                      >
                                        <div>
                                          <label>Communication</label>
                                        </div>
                                        <div>
                                          <input
                                            type="number"
                                            className="small-input border-none-with-padding"
                                            name="percentCommunication"
                                            value={percentCommunication}
                                            onChange={({ target }) => {
                                              this.handlerInput(target.name, target.value);
                                            }}
                                            onBlur={this.calculatePayDividedValues}
                                          />
                                        </div>
                                      </div>
                                      <div
                                        className="flex-box flex-align-center"
                                        style={{
                                          marginBottom: '20px',
                                          justifyContent: 'space-between',
                                        }}
                                      >
                                        <div>
                                          <label>Work</label>
                                        </div>
                                        <div>
                                          <input
                                            type="number"
                                            className="small-input border-none-with-padding"
                                            name="percentWork"
                                            value={percentWork}
                                            onChange={({ target }) => {
                                              this.handlerInput(target.name, target.value);
                                            }}
                                            onBlur={this.calculatePayDividedValues}
                                          />
                                        </div>
                                      </div>
                                      <div
                                        className="flex-box flex-align-center"
                                        style={{
                                          marginBottom: '20px',
                                          justifyContent: 'space-between',
                                        }}
                                      >
                                        <div>
                                          <label>Deliverables</label>
                                        </div>
                                        <div>
                                          <input
                                            type="number"
                                            className="small-input border-none-with-padding"
                                            name="percentDeliverables"
                                            value={percentDeliverables}
                                            onChange={({ target }) => {
                                              this.handlerInput(target.name, target.value);
                                            }}
                                            onBlur={this.calculatePayDividedValues}
                                          />
                                        </div>
                                      </div>
                                    </MDBRow>
                                  </MDBCol>
                                </MDBRow>
                                <MDBRow className="no-margins">
                                  <MDBCol>
                                    <MDBRow style={{ borderTop: '1px solid #1e2f41', borderBottom: '1px solid #1e2f41' }}>
                                      <MDBCol md="6">
                                        <MDBRow className="no-margins">
                                          <h6 style={{ fontWeight: 'bold' }}>Variable Pay (Optional)</h6>
                                        </MDBRow>
                                        <MDBRow className="no-margins">
                                          <MDBCol style={{ paddingLeft: '0px' }}>
                                            <div className="d-flex" style={{ marginBottom: '20px' }}>
                                              <MDBCol>
                                                <label>Amount</label>
                                              </MDBCol>
                                              <MDBCol>
                                                <input
                                                  type="number"
                                                  className="small-input border-none-with-padding"
                                                  name="variableAmount"
                                                  value={vAmount}
                                                  onChange={({ target }) => {
                                                    const value = +target.value || 0;
                                                    this.handlerInput(target.name, value, true);
                                                  }}
                                                />
                                              </MDBCol>
                                            </div>
                                            <div className="d-flex" style={{ marginBottom: '20px' }}>
                                              <MDBCol>
                                                <label>After</label>
                                              </MDBCol>
                                              <MDBCol>
                                                <input
                                                  type="number"
                                                  disabled={(variableType === basicType) || ((variableType === 'Hour') && (basicType === 'Site'))}
                                                  className="small-input border-none-with-padding"
                                                  name="variablePayAfter"
                                                  value={vAfter}
                                                  onChange={({ target }) => {
                                                    const value = +target.value || 0;
                                                    this.handlerInput(target.name, value, true);
                                                  }}
                                                />
                                              </MDBCol>
                                            </div>
                                            <div className="d-flex" style={{ marginBottom: '20px' }}>
                                              <MDBCol>
                                                <label>Max. Qty</label>
                                              </MDBCol>
                                              <MDBCol>
                                                <input
                                                  type="number"
                                                  className="small-input border-none-with-padding"
                                                  name="maxQuantity"
                                                  value={vQty}
                                                  onChange={({ target }) => {
                                                    const value = +target.value || 0;
                                                    this.handlerInput(target.name, value, true);
                                                  }}
                                                />
                                              </MDBCol>
                                            </div>
                                          </MDBCol>
                                          <MDBCol>
                                            <div style={{ marginBottom: '30px', alignItems: 'baseline' }} className="d-flex">
                                              <MDBCol md="4">
                                                <label>Per</label>
                                              </MDBCol>
                                              <MDBCol md="8">
                                                <div className="perhour_device">
                                                  <select
                                                    className="border-none-with-padding"
                                                    name="variableType"
                                                    onChange={({ target }) => {
                                                      this.handlerInput(target.name, target.value);
                                                    }}
                                                  >
                                                    <option value="Hour">Hour</option>
                                                    <option value="Device">Device</option>
                                                    <option value="Site">Site</option>
                                                  </select>
                                                </div>
                                              </MDBCol>
                                            </div>
                                          </MDBCol>
                                        </MDBRow>
                                      </MDBCol>
                                      <MDBCol md="6" className="fundcol">
                                        <MDBRow style={{ justifyContent: 'center' }}>
                                          <MDBCol>
                                            <h6 style={{ marginRight: '0.5rem' }}>Total Amount:</h6>
                                            <label>(Selected work order)</label>
                                          </MDBCol>
                                          <MDBCol className="text-center">
                                            <h6 style={{ color: '#f8c79c', fontWeight: 'bold', fontSize: '20px' }}>
                                              {totalAmount.toLocaleString('en-US', localCurrencySettings)}
                                            </h6>
                                          </MDBCol>
                                        </MDBRow>
                                        <MDBRow style={{ justifyContent: 'center' }}>
                                          <MDBCol>
                                            <h6 style={{ marginRight: '0.5rem' }}>New Total Amount:</h6>
                                            <label>(Selected work order)</label>
                                          </MDBCol>
                                          <MDBCol className="text-center">
                                            <h6 style={{ color: '#f8c79c', fontWeight: 'bold', fontSize: '20px' }}>
                                              {newTotalAmount.toLocaleString('en-US', localCurrencySettings)}
                                            </h6>
                                          </MDBCol>
                                        </MDBRow>
                                        {profile.balance.total_balance && fundsRemaining
                                          ? (
                                            <>
                                              <MDBRow style={{ justifyContent: 'center' }}>
                                                <MDBCol>
                                                  <h6 style={{ marginRight: '0.5rem' }}>Funds Remaining:</h6>
                                                </MDBCol>
                                                <MDBCol className="text-center">
                                                  <h6
                                                    style={{
                                                      color: 'green',
                                                      fontWeight: 'bold',
                                                      fontSize: '20px',
                                                    }}
                                                  >
                                                    {(fundsRemaining).toLocaleString('en-US', localCurrencySettings)}
                                                  </h6>
                                                </MDBCol>
                                              </MDBRow>
                                              <MDBRow style={{ justifyContent: 'center' }}>
                                                <MDBCol>
                                                  <h6 style={{ marginRight: '0.5rem' }}>Funds Needed:</h6>
                                                </MDBCol>
                                                <MDBCol className="text-center">
                                                  <h6
                                                    style={{
                                                      color: 'red',
                                                      fontWeight: 'bold',
                                                      fontSize: '20px',
                                                    }}
                                                  >
                                                    {payOutsideOfApp
                                                      ? '$0.00'
                                                      : (newTotalAmount - totalAmount).toLocaleString('en-US', localCurrencySettings)}
                                                  </h6>
                                                </MDBCol>
                                              </MDBRow>
                                            </>
                                          )
                                          : <></>}
                                      </MDBCol>
                                    </MDBRow>
                                  </MDBCol>
                                </MDBRow>
                              </>
                            )
                            : <></>
                        }
                      </MDBCol>
                    </MDBRow>
                    <MDBRow>
                      <MDBCol md="12" className="h-paddings">
                        <MDBInput
                          filled
                          type="checkbox"
                          id="editPayLocationCheckbox"
                          label="Edit pay location"
                          onChange={(event) => this.handlerUpdatingFields('editPayLocation', event.target.checked)}
                          checked={fieldsToUpdate.has('editPayLocation')}
                        />
                      </MDBCol>
                      {fieldsToUpdate.has('editPayLocation') ?
                        <MDBRow className="no-margins w-100">
                          <MDBCol>
                            <div
                              className="d-flex"
                              title="This can be used if you want to pay in system. Funds will be deducted and payments will be made if this is checked."
                            >
                              <MDBInput
                                filled
                                type="radio"
                                label="Pay within app"
                                id="payWithinApp"
                                name="payOutsideOfApp"
                                checked={!payOutsideOfApp}
                                onChange={({ target }) => {
                                  this.handlerInput(target.name, !target.checked);
                                }}
                              />

                            </div>
                            <div
                              className="d-flex"
                              title="This can be used if you have agreed to pay the contractor directly. No funds will be deducted and no payments will be made if this is checked."
                            >
                              <MDBInput
                                filled
                                type="radio"
                                label="Pay outside app"
                                id="payOutsideOfApp"
                                name="payOutsideOfApp"
                                checked={payOutsideOfApp}
                                onChange={({ target }) => {
                                  this.handlerInput(target.name, target.checked);
                                }}
                              />

                            </div>
                          </MDBCol>
                          <MDBCol md="6" className="fundcol">
                            <MDBRow style={{ justifyContent: 'center' }}>
                              <MDBCol>
                                <h6 style={{ marginRight: '0.5rem' }}>Total Amount:</h6>
                                <label>(Selected work order)</label>
                              </MDBCol>
                              <MDBCol className="text-center">
                                <h6 style={{ color: '#f8c79c', fontWeight: 'bold', fontSize: '20px' }}>
                                  {totalAmount.toLocaleString('en-US', localCurrencySettings)}
                                </h6>
                              </MDBCol>
                            </MDBRow>
                            <MDBRow style={{ justifyContent: 'center' }}>
                              <MDBCol>
                                <h6 style={{ marginRight: '0.5rem' }}>New Total Amount:</h6>
                                <label>(Selected work order)</label>
                              </MDBCol>
                              <MDBCol className="text-center">
                                <h6 style={{ color: '#f8c79c', fontWeight: 'bold', fontSize: '20px' }}>
                                  {newTotalAmount.toLocaleString('en-US', localCurrencySettings)}
                                </h6>
                              </MDBCol>
                            </MDBRow>
                            {profile.balance.total_balance && fundsRemaining
                              ? (
                                <>
                                  <MDBRow style={{ justifyContent: 'center' }}>
                                    <MDBCol>
                                      <h6 style={{ marginRight: '0.5rem' }}>Funds Remaining:</h6>
                                    </MDBCol>
                                    <MDBCol className="text-center">
                                      <h6
                                        style={{
                                          color: 'green',
                                          fontWeight: 'bold',
                                          fontSize: '20px',
                                        }}
                                      >
                                        {(fundsRemaining).toLocaleString('en-US', localCurrencySettings)}
                                      </h6>
                                    </MDBCol>
                                  </MDBRow>
                                  <MDBRow style={{ justifyContent: 'center' }}>
                                    <MDBCol>
                                      <h6 style={{ marginRight: '0.5rem' }}>Funds Needed:</h6>
                                    </MDBCol>
                                    <MDBCol className="text-center">
                                      <h6
                                        style={{
                                          color: 'red',
                                          fontWeight: 'bold',
                                          fontSize: '20px',
                                        }}
                                      >
                                        {payOutsideOfApp
                                          ? '$0.00'
                                          : (newTotalAmount - totalAmount).toLocaleString('en-US', localCurrencySettings)}
                                      </h6>
                                    </MDBCol>
                                  </MDBRow>
                                </>
                              )
                              : <></>}
                          </MDBCol>

                        </MDBRow >
                        : <></>
                      }
                    </MDBRow>
                    <MDBRow>
                      <MDBCol md="12" className="h-paddings">
                        <MDBInput
                          filled
                          type="checkbox"
                          id="replaceContactsCheckbox"
                          label="Replace Contacts"
                          onChange={(event) => this.handlerUpdatingFields('replaceContacts', event.target.checked)}
                          checked={fieldsToUpdate.has('replaceContacts')}
                        />
                      </MDBCol>
                      {fieldsToUpdate.has('replaceContacts')
                        ? (
                          <ContactInfoForWorkOrder
                            handlerChanges={this.handlerContactInfoChanges}
                            contactList={contactInfo}
                          />
                        )
                        : <></>}
                    </MDBRow>
                    <MDBRow>
                      <MDBCol md="12" className="h-paddings">
                        <MDBInput
                          filled
                          type="checkbox"
                          id="deleteAllDocumentsCheckbox"
                          label="Delete All Documents"
                          name="deleteAllDocuments"
                          onChange={(event) => this.handlerUpdatingFields('deleteAllDocuments', event.target.checked)}
                          checked={fieldsToUpdate.has('deleteAllDocuments')}
                        />
                      </MDBCol>
                    </MDBRow>
                    <MDBRow className="background-dark-blue">
                      <MDBCol md="12" className="h-paddings">
                        <MDBInput
                          filled
                          type="checkbox"
                          id="uploadDocumentsCheckbox"
                          label="Upload Documents"
                          onChange={(event) => this.handlerUpdatingFields('uploadDocuments', event.target.checked)}
                          checked={fieldsToUpdate.has('uploadDocuments')}
                        />
                      </MDBCol>
                      {
                        fieldsToUpdate.has('uploadDocuments')
                          ? (
                            <UploadDocumentsToWorkOrder
                              getUploadedDocuments={this.getUploadedDocuments}
                              documents={file}
                              toggleModal={toggleModal}
                            />
                          )
                          : <></>
                      }
                    </MDBRow>
                    <MDBRow className="h-paddings">
                      <MDBCol md="2" className="flex-box align-items-center">
                        <MDBInput
                          filled
                          type="checkbox"
                          id="workOrderTitleCheckbox"
                          label="WO Title: "
                          onChange={(event) => this.handlerUpdatingFields('title', event.target.checked)}
                          checked={fieldsToUpdate.has('title')}
                        />
                      </MDBCol>
                      {
                        fieldsToUpdate.has('title')
                          ? (
                            <MDBCol md="6">
                              <input
                                type="text"
                                className="border-none-with-padding full-width"
                                name="title"
                              />
                            </MDBCol>
                          )
                          : <></>
                      }
                    </MDBRow>
                    <MDBRow className="h-paddings">
                      <MDBCol md="2">
                        <div className="flex-box flex-align-center" style={{ height: '38px' }}>
                          <MDBInput
                            filled
                            type="checkbox"
                            id="workTypeCheckbox"
                            label="Work Type: "
                            onChange={(event) => this.handlerUpdatingFields('workType', event.target.checked)}
                            checked={fieldsToUpdate.has('workType')}
                          />
                        </div>
                      </MDBCol>
                      {
                        fieldsToUpdate.has('workType')
                          ? (
                            <MDBCol>
                              <SelectWorkOrderWorkTypes
                                value={selectedWorkOrderWorkTypes}
                                onChange={(value) => this.handlerInput('selectedWorkOrderWorkTypes', value)}
                              />
                            </MDBCol>
                          )
                          : <></>
                      }
                    </MDBRow>
                    <MDBRow className="h-paddings">
                      <MDBCol className="flex-grow-0">
                        <MDBInput
                          filled
                          type="checkbox"
                          labelClass="text-in-one-line"
                          id="workDescriptionCheckbox"
                          label="Work Description: "
                          onChange={(event) => this.handlerUpdatingFields('description', event.target.checked)}
                          checked={fieldsToUpdate.has('description')}
                        />
                      </MDBCol>
                      {
                        fieldsToUpdate.has('description')
                          ? (
                            <MDBCol md="8">
                              <EditorInput name="description" />
                            </MDBCol>
                          )
                          : <></>
                      }
                    </MDBRow>
                    <MDBRow className="h-paddings">
                      <MDBCol className="flex-grow-0">
                        <MDBInput
                          filled
                          type="checkbox"
                          labelClass="text-in-one-line"
                          id="customToolsCheckbox"
                          label="Tools Required: "
                          onChange={(event) => this.handlerUpdatingFields('customTools', event.target.checked)}
                          checked={fieldsToUpdate.has('customTools')}
                        />
                      </MDBCol>
                      {
                        fieldsToUpdate.has('customTools')
                          ? (
                            <MDBCol md="8">
                              <EditorInput name="customTools" />
                            </MDBCol>
                          )
                          : <></>
                      }
                    </MDBRow>
                    <MDBRow className="h-paddings">
                      <MDBCol className="flex-grow-0">
                        <MDBInput
                          filled
                          type="checkbox"
                          labelClass="text-in-one-line"
                          id="confidentialInformationCheckbox"
                          label="Confidential Information: "
                          onChange={(event) => this.handlerUpdatingFields('confidential', event.target.checked)}
                          checked={fieldsToUpdate.has('confidential')}
                        />
                      </MDBCol>
                      {
                        fieldsToUpdate.has('confidential')
                          ? (
                            <MDBCol md="8">
                              <EditorInput name="confidential" />
                            </MDBCol>
                          )
                          : <></>
                      }
                    </MDBRow>
                    <MDBRow className="h-paddings">
                      <MDBCol className="flex-grow-0 flex-box flex-align-center">
                        <MDBInput
                          filled
                          type="checkbox"
                          id="woCompanyCheckbox"
                          label="Company: "
                          onChange={(event) => this.handlerUpdatingFields('woCompanyId', event.target.checked)}
                          checked={fieldsToUpdate.has('woCompanyId')}
                        />
                      </MDBCol>
                      {
                        fieldsToUpdate.has('woCompanyId')
                          ? (
                            <MDBCol md="8">
                              <SelectWorkOrderCompany
                                name="woCompanyId"
                              />
                            </MDBCol>
                          )
                          : <></>
                      }
                    </MDBRow>
                    <MDBRow className="h-paddings">
                      <MDBCol className="flex-grow-0 flex-box flex-align-center">
                        <MDBInput
                          filled
                          type="checkbox"
                          id="woProjectCheckbox"
                          label="Project: "
                          onChange={(event) => this.handlerUpdatingFields('projectId', event.target.checked)}
                          checked={fieldsToUpdate.has('projectId')}
                        />
                      </MDBCol>
                      {
                        fieldsToUpdate.has('projectId')
                          ? (
                            <MDBCol md="8">
                              <SelectWorkProject name="projectId" />
                            </MDBCol>
                          )
                          : <></>
                      }
                    </MDBRow>
                    <MDBRow style={{ justifyContent: 'flex-end' }}>
                      <MDBCol className="flex-grow-0">
                        <MDBBtn
                          type="submit"
                        >
                          Update
                        </MDBBtn>
                      </MDBCol>
                    </MDBRow>
                  </MDBContainer>
                </form>
              )
          }
        </MDBModalBody>
      </MDBModal>
    );
  }
}

const mapDispatchToProps = {
  toggleModal,
  bulkEditWorkOrders,
  getWorkOrdersForBulk,
  getAccountBalanceByClient,
};

const mapStateToProps = (state) => ({
  modalState: state.modalState.bulkEditWorkOrders,
  activeTab: state.applicationState.activeTab,
  selectedWorkOrders: state.applicationState.selectedWorkOrders,
  profile: state.profile,
  filters: state.workOrder?.filters,
  search: state.workOrder?.searchData,
});

export default connect(mapStateToProps, mapDispatchToProps)(BulkEditWorkOrders);
