import React, { useEffect, useState, useCallback } from 'react';
import {
  MDBContainer, MDBCol, MDBTable, MDBTableBody, MDBTableHead,
} from 'mdbreact';
import axios from 'axios';
import { connect } from 'react-redux';
import { toggleModal } from '../../storage/actions/modalsActions';
import { setCurrentApplicants, setCurrentWorkOrder } from '../../storage/actions/workOrdersActions';
import Offer from '../../components/layout/Offer';
// eslint-disable-next-line import/no-cycle
import Modal from '../../components/modal';
import SearchContractorCard from '../../components/layout/contractorCards/SearchContractorCard';
import CertificatesList from '../../components/layout/certificates/CertificatesList';
import Notification from '../../components/notification';
import Loader from '../../components/dashboard/common/Loader';
import { orderCertifications } from '../../utils/certificatesOrdering';

const FindContractor = ({
  setCurrentApplicants,
  setCurrentWorkOrder,
  toggleModal,
  mainContainer,
  auth,
  profile,
  isReload,
}) => {
  const [contractors, setContractors] = useState([]);
  const [certificates, setCertificates] = useState([]);
  const [selectedCertificates, setSelectedCertificates] = useState(new Set());
  const [searchValue, setSearchValue] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [userGroup, setUserGroup] = useState(false);
  const [userWorkedBefore, setUserWorkedBefore] = useState(false);

  useEffect(() => {
    fetchCertificates();
    setCurrentWorkOrder();
  }, []);

  useEffect(() => {
    fetchContractors(searchValue);
  }, [searchValue]);

  const fetchContractors = useCallback(({ range, search, search_zip: searchZip }) => {
    setIsLoading(true);
    const searchField = search ? `"${search.replaceAll(' ', '" "')}"` : '';
    axios.get('/api/users/contractors', {
      params: {
        range,
        search: searchField,
        zipcode: searchZip,
        userGroup,
        userWorkedBefore,
        certificates: [...selectedCertificates].toString(),
      },
    })
      .then(res => {
        setContractors(res.data.contractors || []);
        setIsLoading(false);
      }).catch(error => {
        setIsLoading(false);
        console.error('error', error);
        Notification('error', {
          message: error.response?.data.message || error.message,
        });
      });
  }, [selectedCertificates, userGroup, userWorkedBefore]);
  

  const fetchCertificates = () => {
    axios.get('/api/certificates')
      .then(res => {
        const certifications = orderCertifications(res.data.certificates || [])
        setCertificates(certifications);
      }).catch(error => {
        Notification('error', {
          message: error.response?.data.message || error.message,
        });
      });
  };

  const openContractorDetails = contractor => {
    toggleModal(true, 'contractorDetailsModal', false, { contractorId: contractor.userId });
  };

  const showBlockModalForCurrentContractor = contractor => {
    toggleModal(true, 'blockModal', false, { contractorId: contractor.userId });
  };

  const openContractorInviteSelectWorkOrder = contractor => {
    setCurrentApplicants({ contractor_details: contractor });
    toggleModal(true, 'contractorInviteSelectWorkOrder');
  };

  const handlerSearchContractors = newSearchValue => {
    setSearchValue(newSearchValue);
  };

  const handlerCertificatesCheckboxChange = ({ target }) => {
    const newSet = new Set([...selectedCertificates]);
    const command = target.checked ? 'add' : 'delete';
    newSet[command](target.value);
    setSelectedCertificates(newSet);
  };

  const checkGroup = ({ target }) => {
    setUserGroup(target.checked)
  }
  const checkWorkedWithBefore = ({ target }) => {
    setUserWorkedBefore(target.checked)
  }

  return (
    <MDBContainer className="clientfindiv" fluid>
      <Offer
        getSearched={handlerSearchContractors}
        hideControlButton
        showInputRange
        defaultRange={NaN}
      />
      <CertificatesList
        certificates={certificates}
        handlerCheck={handlerCertificatesCheckboxChange}
        checkGroup={checkGroup}
        checkWorkedWithBefore={checkWorkedWithBefore}
        userGroup={userGroup}
        userWorkedBefore={userWorkedBefore}
      />

      <MDBContainer fluid className="d-flex">
        <MDBCol md="10" className="clientfind10">
          <MDBTable className="clientfind_table" borderless style={{ marginTop: '-10px' }}>
            <MDBTableHead />
            <MDBTableBody>
              {
                isLoading
                  ? (
                    <tr>
                      <td>
                        <div className="flex-box justify-content-center">
                          <Loader />
                        </div>
                      </td>
                    </tr>
                  )
                  : 
                  contractors.map((contractor) => (
                    <SearchContractorCard
                      key={contractor._id}
                      contractor={contractor}
                      inviteContractor={openContractorInviteSelectWorkOrder}
                      openInfo={openContractorDetails}
                      block={profile?.company?.blocked_users?.includes(contractor.userId)}
                      showBlockModalForCurrentContractor={showBlockModalForCurrentContractor}
                    />
                  ))
              }
            </MDBTableBody>
          </MDBTable>
        </MDBCol>
      </MDBContainer>
      <Modal mainContainer={mainContainer.current} />
    </MDBContainer>
  );
};

const mapDispatchToProps = {
  toggleModal,
  setCurrentApplicants,
  setCurrentWorkOrder,
};

const mapStateToProps = state => ({
  auth: state.auth,
  profile: state.profile,
  isReload: state.applicationState.isReload,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(FindContractor);
