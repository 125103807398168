import React from 'react';
import {
  MDBModal,
  MDBRow,
  MDBIcon,
  MDBModalBody,
  MDBCol,
  MDBInput,
  MDBBtn,
  MDBContainer,
} from 'mdbreact';
import axios from 'axios';
import DatePicker from 'react-datepicker';
import Select from 'react-select';
import { connect } from 'react-redux';
import { white as customSelectStyle } from '../../../utils/customSelectStyle';
import { workOrderTypes } from '../../../utils/constans';
import { toggleModal } from '../../../storage/actions/modalsActions';
import { setFiltersObject } from '../../../storage/actions/workOrdersActions';
import getWorkOrderStatusSet from '../../../utils/getWorkOrderStatusSet';

class ModalWOFilters extends React.Component {
  constructor(props) {
    super(props);
    const filters = props.filters || {};
    const selectedWOTypes = getWorkOrderStatusSet(
      filters.selectedWOTypes,
      filters.needFilterByRouterStatus,
      filters.needFilterWithoutRouterStatus,
    );
    this.state = {
      form: {
        selectedCompanies: new Set(filters.selectedCompanies || []),
        contractorId: filters.contractorId || '',
        startDate: filters.startDate || {},
        endDate: filters.endDate || {},
        selectProject: filters.selectProject || '',
        selectCompany: filters.selectCompany || '',
        workOrderId: filters.workOrderId || '',
        workOrderTitle: filters.workOrderTitle || '',
        selectedWOTypes,
        cityName: filters.cityName || '',
        selectState: filters.selectState || '',
        zipCode: filters.zipCode || '',
        selectCountry: filters.selectCountry || '',
        selectContractorCountry: filters.selectContractorCountry || '',
        siteId: filters.siteId || '',
        siteName: filters.siteName || '',
        payRate: filters.payRate || {},
        createdDate: filters.createdDate || {},
        assignedDate: filters.assignedDate || {},
        completedDate: filters.completedDate || {},
        approvedDate: filters.approvedDate || {},
        paidDate: filters.paidDate || {},
        selectedWorkTypes: new Set(filters.selectedWorkTypes || []),
        payLocation: filters.payLocation || '',
      },
      companies: [],
      projects: [],
      workOrderTypes,
      stateArray: [],
      countries: [],
      rateTypes: [
        {
          value: 'Hour',
          label: 'Hour',
        },
        {
          value: 'Device',
          label: 'Device',
        },
        {
          value: 'Site',
          label: 'Site / Flat',
        },
      ],
      workTypes: [],
      payLocations: [
        {
          value: 'all',
          label: 'All',
        },
        {
          value: 'payOutsideOfApp',
          label: 'Pay outside app',
        },
        {
          value: 'payWithinApp',
          label: 'Pay within app',
        },
      ],
    };
  }

  componentDidMount() {
    this.getCompanies();
    this.getCountries();
    this.getWorkTypes();
    this.getProjects();
    this.getWOCompanies();
  }

  componentDidUpdate(prevProps, prevState) {
    if ([...prevState.form.selectedCompanies].length
        !== [...this.state.form.selectedCompanies].length) {
      this.getWOCompanies();
      this.getProjects();
    }
  }

  getCompanies = () => {
    axios.get('/api/admin/companies')
      .then(res => {
        if (!res.data?.payload?.companies) throw new Error('Can\'t get list of companies!');
        const { companies } = res.data.payload;
        this.setState({ companies });
      })
      .catch(error => {
        console.error(error);
      });
  }

  resetFilters = () => {
    this.setState({
      form: {
        selectedCompanies: '',
        contractorId: '',
        startDate: {},
        endDate: {},
        selectProject: '',
        selectCompany: '',
        workOrderId: '',
        workOrderTitle: '',
        selectedWOTypes: new Set(),
        cityName: '',
        selectState: '',
        zipCode: '',
        selectCountry: '',
        selectContractorCountry: '',
        siteId: '',
        siteName: '',
        payRate: {},
        createdDate: {},
        assignedDate: {},
        completedDate: {},
        approvedDate: {},
        paidDate: {},
        selectedWorkTypes: new Set(),
        payLocation: '',
      },
    });
  }

  handlerOnChange = ({ name, value }) => {
    this.setState(
      cur => ({ form: { ...cur.form, [name]: value || '' } }),
      () => {
        if (name === 'selectCountry') this.getStates();
      },
    );
  }

  handlerSetChanges = ({ target }) => {
    const newSet = new Set([...this.state.form[target.name]]);
    const command = target.checked ? 'add' : 'delete';
    newSet[command](target.value);
    this.setState(cur => ({ form: { ...cur.form, [target.name]: newSet } }));
  }

  handlerObjectChanges = (field, name, value) => {
    this.setState(cur => ({
      form: { ...cur.form, [field]: { ...cur.form[field], [name]: value || '' } },
    }));
  }

  closeModal = () => this.props.toggleModal(false, 'filtersModal');

  handlerAccept = event => {
    event.preventDefault();
    const filters = this.getFilters();
    this.props.getFilters(filters);
    this.closeModal();
  }

  getFilters = () => {
    const fields = Object.keys(this.state.form);
    if (!fields.length) return null;
    const result = {};
    fields
      .filter(key => (
        this.state.form[key] !== ''
            && (
              !(this.state.form[key] instanceof Set)
                || this.state.form[key].size > 0
            )
            && (
              !(this.state.form[key] instanceof Object)
                || (this.state.form[key] instanceof Set)
                || (this.state.form[key] instanceof Date)
                || this.state.form[key].from
                || this.state.form[key].to
                || this.state.form[key].value
                || this.state.form[key].length
            )
      ))

      .forEach(key => {
        if (this.state.form[key] instanceof Set) {
          const newSet = new Set([...this.state.form[key]]);
          if (key === 'selectedWOTypes' && newSet.has('routed')) {
            result.needFilterByRouterStatus = {
              published: newSet.has('published'),
              draft: newSet.has('draft'),
            };
            newSet.add('published');
            newSet.add('draft');
          }
          if (
            key === 'selectedWOTypes'
                && !newSet.has('routed')
                && (newSet.has('published') || newSet.has('draft'))
          ) result.needFilterWithoutRouterStatus = true;
          newSet.delete('routed');
          result[key] = [...newSet];
          return;
        }
        if (this.state.form[key] instanceof Date) {
          const newDate = {};
          newDate.from = this.state.form[key];
          newDate.to = new Date(new Date(this.state.form[key]).setHours(24, 0, 0, -1));
          result[key] = newDate;
          return;
        }
        result[key] = this.state.form[key];
      });
    return result;
  }

  getCountries = () => {
    axios.get('/api/address/getAllCountries')
      .then(res => {
        if (res.data.length && res.data.length > 0) {
          return this.setState({
            countries: res.data.map(item => ({
              ...item,
              label: item.name,
              value: item.isoCode,
            })),
          }, this.getStates);
        }
        throw new Error('Can\'t get list of countries!');
      })
      .catch(error => {
        console.error(error);
      });
  }

  getStates = () => {
    axios.get('/api/address/getStatesOfCountry', {
      params: { countryId: this.state?.form?.selectCountry?.value || '' },
    })
      .then(res => {
        this.setState({
          stateArray: res.data?.payload?.states || [],
        });
      })
      .catch(error => {
        console.error(error);
      });
  }

  getWorkTypes = () => {
    axios.get('/api/users/getTools')
      .then(res => {
        if (res.data.Tools && res.data.Tools.length > 0) {
          return this.setState({ workTypes: res.data.Tools });
        }
        throw new Error('Can\'t get list of work types!');
      })
      .catch(error => {
        console.error(error);
      });
  }

  createUrlParamsBasedOnCompanies = (selectedCompanies) => {
    const companies = [...selectedCompanies].map((item) => item.value);
    return ({ companies });
  }

  // eslint-disable-next-line consistent-return
  getProjects = () => {
    const params = this.createUrlParamsBasedOnCompanies(this.state.form.selectedCompanies);
    if (!params) return this.setState({ projects: [] });
    axios.get('/api/projects', { params })
      .then(res => {
        if (res.data?.payload?.data?.length) {
          return this.setState({
            projects: res.data.payload.data.map(item => ({
              label: item.name,
              value: item._id,
            })),
          });
        }
        throw new Error('Can\'t get list of projects!');
      })
      .catch(error => {
        console.error(error);
      });
  }

  // eslint-disable-next-line consistent-return
  getWOCompanies = () => {
    const params = this.createUrlParamsBasedOnCompanies(this.state.form.selectedCompanies);
    if (!params) return this.setState({ woCompanies: [] });
    axios.get('/api/wo-companies', { params })
      .then(res => {
        if (res.data?.payload?.data?.length) {
          return this.setState({
            woCompanies: res.data.payload.data.map(item => ({
              label: item.name,
              value: item._id,
            })),
          });
        }
        throw new Error('Can\'t get list of companies!');
      })
      .catch(error => {
        console.error(error);
      });
  }

  render() {
    const { isOpen } = this.props;
    const {
      companies,
      projects,
      woCompanies,
      workOrderTypes,
      stateArray, countries,
      rateTypes,
      workTypes,
      payLocations,
    } = this.state;
    const {
      selectedCompanies,
      contractorId,
      startDate,
      endDate,
      selectProject,
      selectCompany,
      workOrderId, workOrderTitle,
      selectedWOTypes,
      cityName, selectState, zipCode, selectCountry, selectContractorCountry,
      siteId, siteName,
      payRate,
      createdDate, assignedDate, completedDate, approvedDate, paidDate, selectedWorkTypes,
      payLocation,
    } = this.state.form;

    return (
      <MDBModal
        isOpen={isOpen}
        toggle={this.closeModal}
        className="wodpop filters-modal"
        centered
      >
        <MDBModalBody style={{ backgroundColor: '#1f2f41', color: '#fff' }}>
          <MDBContainer>
            <MDBRow className="h-paddings" style={{ justifyContent: 'flex-end', paddingRight: '10px' }}>
              <MDBIcon
                far
                icon="times-circle"
                onClick={this.closeModal}
                size="lg"
                className="blue-grey-text"
              />
            </MDBRow>

            <MDBRow className="h-paddings" style={{ height: 'max-content' }}>
              <MDBCol md="3" className="no-margins v-paddings flex-box flex-align-center">
                Company
              </MDBCol>
              <MDBCol md="4" className="no-margins v-paddings">
                <Select
                  isClearable
                  isSearchable
                  isMulti
                  closeMenuOnSelect={false}
                  options={companies}
                  value={[...selectedCompanies]}
                  styles={customSelectStyle}
                  onChange={value => this.handlerOnChange({ name: 'selectedCompanies', value: new Set(value || []) })}
                />
              </MDBCol>
            </MDBRow>

            <MDBRow className="h-paddings" style={{ height: 'max-content' }}>
              <MDBCol md="3" className="no-margins v-paddings flex-box flex-align-center">
                Contractor ID
              </MDBCol>
              <MDBCol md="4" className="no-margins v-paddings">
                <input
                  type="text"
                  name="contractorId"
                  className="input-field"
                  style={{ width: 'inherit' }}
                  placeholder="Type contractor id"
                  value={contractorId}
                  onChange={event => this.handlerOnChange(event.target)}
                />
              </MDBCol>
            </MDBRow>
            <MDBRow className="h-paddings" style={{ height: 'max-content' }}>
              <MDBCol md="3" className="no-margins v-paddings flex-box flex-align-center">
                Start Date
              </MDBCol>
              <MDBCol md="2" className="no-margins v-paddings">
                <DatePicker
                  className="no-margins input-field width-inherit"
                  style={{ width: '7rem' }}
                  name="startDateFrom"
                  selected={startDate.from}
                  onChange={date => this.handlerObjectChanges('startDate', 'from', date)}
                  dateFormat="MM/dd/yyyy"
                  placeholderText="From"
                />
              </MDBCol>
              <MDBCol md="2" className="no-margins v-paddings">
                <DatePicker
                  className="no-margins input-field width-inherit"
                  style={{ width: '7rem' }}
                  name="startDateTo"
                  selected={startDate.to}
                  onChange={date => this.handlerObjectChanges('startDate', 'to', date)}
                  dateFormat="MM/dd/yyyy"
                  placeholderText="To"
                />
              </MDBCol>
            </MDBRow>
            <MDBRow className="h-paddings" style={{ height: 'max-content' }}>
              <MDBCol md="3" className="no-margins v-paddings flex-box flex-align-center">
                End Date
              </MDBCol>
              <MDBCol md="2" className="no-margins v-paddings">
                <DatePicker
                  className="no-margins input-field width-inherit"
                  style={{ width: '7rem' }}
                  name="endDateFrom"
                  selected={endDate.from}
                  onChange={date => this.handlerObjectChanges('endDate', 'from', date)}
                  dateFormat="MM/dd/yyyy"
                  placeholderText="From"
                />
              </MDBCol>
              <MDBCol md="2" className="no-margins v-paddings">
                <DatePicker
                  className="no-margins input-field width-inherit"
                  style={{ width: '7rem' }}
                  name="endDateTo"
                  selected={endDate.to}
                  onChange={date => this.handlerObjectChanges('endDate', 'to', date)}
                  dateFormat="MM/dd/yyyy"
                  placeholderText="To"
                />
              </MDBCol>
            </MDBRow>
            <MDBRow className="h-paddings" style={{ height: 'max-content' }}>
              <MDBCol md="3" className="no-margins v-paddings flex-box flex-align-center">
                Customers
              </MDBCol>
              <MDBCol md="4" className="no-margins v-paddings">
                <Select
                  isClearable
                  isSearchable
                  options={woCompanies}
                  value={selectCompany}
                  styles={customSelectStyle}
                  onChange={value => this.handlerOnChange({ name: 'selectCompany', value })}
                />
              </MDBCol>
            </MDBRow>
            <MDBRow className="h-paddings" style={{ height: 'max-content' }}>
              <MDBCol md="3" className="no-margins v-paddings flex-box flex-align-center">
                Project
              </MDBCol>
              <MDBCol md="4" className="no-margins v-paddings">
                <Select
                  isClearable
                  isSearchable
                  isMulti
                  options={projects}
                  value={[...selectProject]}
                  styles={customSelectStyle}
                  onChange={value => this.handlerOnChange({ name: 'selectProject', value: new Set(value || []) })}
                />
              </MDBCol>
            </MDBRow>
            <MDBRow className="h-paddings" style={{ height: 'max-content' }}>
              <MDBCol md="3" className="no-margins v-paddings flex-box flex-align-center">
                Company WO ID
              </MDBCol>
              <MDBCol md="4" className="no-margins v-paddings">
                <input
                  type="text"
                  name="workOrderId"
                  className="input-field"
                  style={{ width: 'inherit' }}
                  placeholder="Type Company WO ID"
                  value={workOrderId}
                  onChange={event => this.handlerOnChange(event.target)}
                />
              </MDBCol>
            </MDBRow>
            <MDBRow className="h-paddings" style={{ height: 'max-content' }}>
              <MDBCol md="3" className="no-margins v-paddings flex-box flex-align-center">
                WO Title
              </MDBCol>
              <MDBCol md="4" className="no-margins v-paddings">
                <input
                  type="text"
                  name="workOrderTitle"
                  className="input-field"
                  style={{ width: 'inherit' }}
                  placeholder="Type work order title"
                  value={workOrderTitle}
                  onChange={event => this.handlerOnChange(event.target)}
                />
              </MDBCol>
            </MDBRow>
            <MDBRow className="h-paddings" style={{ height: 'max-content' }}>
              <MDBCol md="3" className="no-margins v-paddings flex-box flex-align-center">
                WO Status
              </MDBCol>
              <MDBCol md="9" className="no-margins v-paddings flex-box" style={{ flexWrap: 'wrap' }}>
                {
                    workOrderTypes && workOrderTypes.map(workOrderType => (
                      <MDBInput
                        key={workOrderType.value}
                        className="float-right"
                        label={workOrderType.title}
                        type="checkbox"
                        id={workOrderType.value}
                        value={workOrderType.value}
                        name="selectedWOTypes"
                        checked={selectedWOTypes.has(workOrderType.value)}
                        onChange={this.handlerSetChanges}
                      />
                    ))
                  }
              </MDBCol>
            </MDBRow>
            <MDBRow className="h-paddings" style={{ height: 'max-content' }}>
              <MDBCol md="3" className="no-margins v-paddings flex-box flex-align-center">
                City
              </MDBCol>
              <MDBCol md="4" className="no-margins v-paddings">
                <input
                  type="text"
                  name="cityName"
                  className="input-field"
                  style={{ width: 'inherit' }}
                  placeholder="Type city name"
                  value={cityName}
                  onChange={event => this.handlerOnChange(event.target)}
                />
              </MDBCol>
            </MDBRow>
            <MDBRow className="h-paddings" style={{ height: 'max-content' }}>
              <MDBCol md="3" className="no-margins v-paddings flex-box flex-align-center">
                State
              </MDBCol>
              <MDBCol md="4" className="no-margins v-paddings">
                <Select
                  isClearable
                  isSearchable
                  options={stateArray}
                  value={selectState}
                  styles={customSelectStyle}
                  onChange={value => this.handlerOnChange({ name: 'selectState', value })}
                />
              </MDBCol>
            </MDBRow>
            <MDBRow className="h-paddings" style={{ height: 'max-content' }}>
              <MDBCol md="3" className="no-margins v-paddings flex-box flex-align-center">
                Zip
              </MDBCol>
              <MDBCol md="4" className="no-margins v-paddings">
                <input
                  type="text"
                  name="zipCode"
                  className="input-field"
                  style={{ width: 'inherit' }}
                  placeholder="Type zip code"
                  value={zipCode}
                  onChange={event => this.handlerOnChange(event.target)}
                />
              </MDBCol>
            </MDBRow>
            <MDBRow className="h-paddings" style={{ height: 'max-content' }}>
              <MDBCol md="3" className="no-margins v-paddings flex-box flex-align-center">
                Country
              </MDBCol>
              <MDBCol md="4" className="no-margins v-paddings">
                <Select
                  isClearable
                  options={countries}
                  value={selectCountry}
                  styles={customSelectStyle}
                  onChange={value => this.handlerOnChange({ name: 'selectCountry', value })}
                />
              </MDBCol>
            </MDBRow>
            <MDBRow className="h-paddings" style={{ height: 'max-content' }}>
              <MDBCol md="3" className="no-margins v-paddings flex-box flex-align-center">
                Site ID
              </MDBCol>
              <MDBCol md="4" className="no-margins v-paddings">
                <input
                  type="text"
                  name="siteId"
                  className="input-field"
                  style={{ width: 'inherit' }}
                  placeholder="Type site id"
                  value={siteId}
                  onChange={event => this.handlerOnChange(event.target)}
                />
              </MDBCol>
            </MDBRow>
            <MDBRow className="h-paddings" style={{ height: 'max-content' }}>
              <MDBCol md="3" className="no-margins v-paddings flex-box flex-align-center">
                Site Name
              </MDBCol>
              <MDBCol md="4" className="no-margins v-paddings">
                <input
                  type="text"
                  name="siteName"
                  className="input-field"
                  style={{ width: 'inherit' }}
                  placeholder="Type site name"
                  value={siteName}
                  onChange={event => this.handlerOnChange(event.target)}
                />
              </MDBCol>
            </MDBRow>
            <MDBRow className="h-paddings" style={{ height: 'max-content', alignItems: 'center' }}>
              <MDBCol md="3" className="no-margins v-paddings flex-box flex-align-center">
                Pay Rate
              </MDBCol>
              <MDBCol md="2" className="no-margins v-paddings">
                <input
                  type="text"
                  name="payRateFrom"
                  className="input-field width-inherit"
                  placeholder="From"
                  value={payRate.from}
                  onChange={event => this.handlerObjectChanges('payRate', 'from', parseFloat(event.target.value) || 0)}
                />
              </MDBCol>
              -
              <MDBCol md="2" className="no-margins v-paddings">
                <input
                  type="text"
                  name="payRateTo"
                  className="input-field width-inherit"
                  placeholder="To"
                  value={payRate.to}
                  onChange={event => this.handlerObjectChanges('payRate', 'to', parseFloat(event.target.value) || 0)}
                />
              </MDBCol>
              per
              <MDBCol md="3" className="no-margins v-paddings flex-box align-center">
                <Select
                  isClearable
                  options={rateTypes}
                  value={payRate.type}
                  styles={customSelectStyle}
                  onChange={value => this.handlerObjectChanges('payRate', 'type', value)}
                />
              </MDBCol>
            </MDBRow>
            <MDBRow className="h-paddings" style={{ height: 'max-content' }}>
              <MDBCol md="3" className="no-margins v-paddings flex-box flex-align-center">
                Payment Location
              </MDBCol>
              <MDBCol md="4" className="no-margins v-paddings flex-box" style={{ flexWrap: 'wrap' }}>
                <Select
                  options={payLocations}
                  value={payLocation}
                  styles={customSelectStyle}
                  onChange={value => this.handlerOnChange({ name: 'payLocation', value })}
                />
              </MDBCol>
            </MDBRow>
            <MDBRow className="h-paddings" style={{ height: 'max-content' }}>
              <MDBCol md="3" className="no-margins v-paddings flex-box flex-align-center">
                Contractors Country
              </MDBCol>
              <MDBCol md="4" className="no-margins v-paddings">
                <Select
                  isClearable
                  options={countries}
                  value={selectContractorCountry}
                  styles={customSelectStyle}
                  onChange={value => this.handlerOnChange({ name: 'selectContractorCountry', value })}
                />
              </MDBCol>
            </MDBRow>
            <MDBRow className="h-paddings" style={{ height: 'max-content' }}>
              <MDBCol md="3" className="no-margins v-paddings flex-box flex-align-center">
                Date Created
              </MDBCol>
              <MDBCol md="2" className="no-margins v-paddings">
                <DatePicker
                  className="no-margins input-field width-inherit"
                  style={{ width: '7rem' }}
                  name="createdDateFrom"
                  selected={createdDate.from}
                  onChange={date => this.handlerObjectChanges('createdDate', 'from', date)}
                  dateFormat="MM/dd/yyyy"
                  placeholderText="From"
                />
              </MDBCol>
              <MDBCol md="2" className="no-margins v-paddings">
                <DatePicker
                  className="no-margins input-field width-inherit"
                  style={{ width: '7rem' }}
                  name="createdDateTo"
                  selected={createdDate.to}
                  onChange={date => this.handlerObjectChanges('createdDate', 'to', date)}
                  dateFormat="MM/dd/yyyy"
                  placeholderText="To"
                />
              </MDBCol>
            </MDBRow>
            <MDBRow className="h-paddings" style={{ height: 'max-content' }}>
              <MDBCol md="3" className="no-margins v-paddings flex-box flex-align-center">
                Date Assigned
              </MDBCol>
              <MDBCol md="2" className="no-margins v-paddings">
                <DatePicker
                  className="no-margins input-field width-inherit"
                  style={{ width: '7rem' }}
                  name="assignedDateFrom"
                  selected={assignedDate.from}
                  onChange={date => this.handlerObjectChanges('assignedDate', 'from', date)}
                  dateFormat="MM/dd/yyyy"
                  placeholderText="From"
                />
              </MDBCol>
              <MDBCol md="2" className="no-margins v-paddings">
                <DatePicker
                  className="no-margins input-field width-inherit"
                  style={{ width: '7rem' }}
                  name="assignedDateTo"
                  selected={assignedDate.to}
                  onChange={date => this.handlerObjectChanges('assignedDate', 'to', date)}
                  dateFormat="MM/dd/yyyy"
                  placeholderText="To"
                />
              </MDBCol>
            </MDBRow>
            <MDBRow className="h-paddings" style={{ height: 'max-content' }}>
              <MDBCol md="3" className="no-margins v-paddings flex-box flex-align-center">
                Date Completed
              </MDBCol>
              <MDBCol md="2" className="no-margins v-paddings">
                <DatePicker
                  className="no-margins input-field width-inherit"
                  style={{ width: '7rem' }}
                  name="completedDateFrom"
                  selected={completedDate.from}
                  onChange={date => this.handlerObjectChanges('completedDate', 'from', date)}
                  dateFormat="MM/dd/yyyy"
                  placeholderText="From"
                />
              </MDBCol>
              <MDBCol md="2" className="no-margins v-paddings">
                <DatePicker
                  className="no-margins input-field width-inherit"
                  style={{ width: '7rem' }}
                  name="completedDateTo"
                  selected={completedDate.to}
                  onChange={date => this.handlerObjectChanges('completedDate', 'to', date)}
                  dateFormat="MM/dd/yyyy"
                  placeholderText="To"
                />
              </MDBCol>
            </MDBRow>
            <MDBRow className="h-paddings" style={{ height: 'max-content' }}>
              <MDBCol md="3" className="no-margins v-paddings flex-box flex-align-center">
                Date Approved
              </MDBCol>
              <MDBCol md="2" className="no-margins v-paddings">
                <DatePicker
                  className="no-margins input-field width-inherit"
                  style={{ width: '7rem' }}
                  name="approvedDateFrom"
                  selected={approvedDate.from}
                  onChange={date => this.handlerObjectChanges('approvedDate', 'from', date)}
                  dateFormat="MM/dd/yyyy"
                  placeholderText="From"
                />
              </MDBCol>
              <MDBCol md="2" className="no-margins v-paddings">
                <DatePicker
                  className="no-margins input-field width-inherit"
                  style={{ width: '7rem' }}
                  name="approvedDateTo"
                  selected={approvedDate.to}
                  onChange={date => this.handlerObjectChanges('approvedDate', 'to', date)}
                  dateFormat="MM/dd/yyyy"
                  placeholderText="To"
                />
              </MDBCol>
            </MDBRow>
            <MDBRow className="h-paddings" style={{ height: 'max-content' }}>
              <MDBCol md="3" className="no-margins v-paddings flex-box flex-align-center">
                Date Paid
              </MDBCol>
              <MDBCol md="2" className="no-margins v-paddings">
                <DatePicker
                  className="no-margins input-field width-inherit"
                  style={{ width: '7rem' }}
                  name="paidDateFrom"
                  selected={paidDate.from}
                  onChange={date => this.handlerObjectChanges('paidDate', 'from', date)}
                  dateFormat="MM/dd/yyyy"
                  placeholderText="From"
                />
              </MDBCol>
              <MDBCol md="2" className="no-margins v-paddings">
                <DatePicker
                  className="no-margins input-field width-inherit"
                  style={{ width: '7rem' }}
                  name="paidDateTo"
                  selected={paidDate.to}
                  onChange={date => this.handlerObjectChanges('paidDate', 'to', date)}
                  dateFormat="MM/dd/yyyy"
                  placeholderText="To"
                />
              </MDBCol>
            </MDBRow>
            <MDBRow className="h-paddings" style={{ height: 'max-content' }}>
              <MDBCol md="3" className="no-margins v-paddings flex-box flex-align-center">
                Type(s) of work
              </MDBCol>
              <MDBCol md="9" className="no-margins v-paddings flex-box" style={{ flexWrap: 'wrap' }}>
                {
                    workTypes && workTypes.map(workType => (
                      <MDBInput
                        key={workType}
                        className="float-right"
                        label={workType}
                        type="checkbox"
                        id={workType}
                        value={workType}
                        name="selectedWorkTypes"
                        checked={selectedWorkTypes.has(workType)}
                        onChange={this.handlerSetChanges}
                      />
                    ))
                  }
              </MDBCol>
            </MDBRow>
            <MDBRow style={{ justifyContent: 'flex-end' }}>
              <MDBBtn
                color="orange"
                className="signbutton"
                onClick={this.resetFilters}
              >
                Reset Filters
              </MDBBtn>
              <MDBBtn
                color="info"
                className="signbutton"
                onClick={(e) => {
                  this.handlerAccept(e);
                }}
              >
                Accept
              </MDBBtn>
              <MDBBtn
                color="red"
                className="signbutton"
                onClick={e => {
                  e.preventDefault();
                  this.closeModal();
                }}
              >
                Close
              </MDBBtn>
            </MDBRow>
          </MDBContainer>
        </MDBModalBody>
      </MDBModal>
    );
  }
}

const mapDispatchToProps = {
  toggleModal,
  getFilters: setFiltersObject,
};

const mapStateToProps = state => ({
  isOpen: state.modalState?.filtersModal?.isOpen,
  filters: state?.workOrder?.filters,
});

export default connect(mapStateToProps, mapDispatchToProps)(ModalWOFilters);
